function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
import { FetchWrapper } from './fetch-wrapper';
var resetdefaultSettings = {
  "ClientId": "0a1df890-bfdb-44ac-b3f0-96aab07afff1",
  "TenantName": "dgsidtest",
  "TenantDomain": "onmicrosoft.com",
  "RedirectUrl": "https://hcpportal.local.cas.dgs.com/~/oneIdlogin",
  "LoginUrl": "/~/oneIdlogin",
  "ApiBaseUrl": "https://dev.api.dgs.com/OneCustomer-Dev/v1",
  "CultureCode": "en",
  "UserListSize": 30,
  "UseDataLocation": false
};
var resetPasswordComponent = document.querySelector('.component.remotecare-reset-password');
function getSettingsFromComponent(component) {
  var settingsElement = component.querySelector('script[data-hcpid="reset-password-settings"]');
  try {
    var settingsFromElement = JSON.parse(settingsElement.textContent);
    return _objectSpread(_objectSpread({}, resetdefaultSettings), settingsFromElement);
  } catch (e) {
    return resetdefaultSettings;
  }
}
function validateEmail(input) {
  var emailRegEx = /^[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+(?:\.[a-zA-Z0-9!#$%&amp;'+^_`{}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$/;
  var inputParent = input.parentElement;
  var isOk = emailRegEx.test(input.value);
  if (!isOk) {
    inputParent.classList.add('error');
  } else {
    inputParent.classList.remove('error');
  }
  return isOk;
}
if (resetPasswordComponent) {
  var settings = getSettingsFromComponent(resetPasswordComponent);
  FetchWrapper.init(settings);
  var btnaccept = resetPasswordComponent.querySelector('button.button-submit-password');
  var emailtxt = resetPasswordComponent.querySelector('input.reset-email');
  var errortext = resetPasswordComponent.querySelector('span.submit-error-text');
  var btnOk = resetPasswordComponent.querySelector('button.button-done-redirect');
  var resetBox = resetPasswordComponent.querySelector('div.remotecare-reset-password-box');
  var resetDoneBox = resetPasswordComponent.querySelector('div.remotecare-reset-password-box-done');
  btnaccept.addEventListener("click", function (e) {
    errortext.style.display = 'none';
    if (validateEmail(emailtxt)) {
      FetchWrapper.resetPassword(emailtxt.value, false).then(function () {
        resetBox.style.display = 'none';
        resetDoneBox.style.display = 'block';
      }).catch(function (error) {
        errortext.style.display = 'block';
      });
    }
  });
  btnOk.addEventListener("click", function (e) {
    window.location.href = settings.LoginUrl;
  });
}