require('core-js');require('regenerator-runtime/runtime');import $ from 'jquery';
window.jQuery = $;
window.$ = $;
import 'whatwg-fetch';

// FOUNDATION
require('../../../wdh-foundation/dgs/dgs-api.js');
require('../../../wdh-foundation/dgs/dgs-events.js');
// FEATURE
require('./feature/component-intro-banner');
require('./feature/component-text-image-spot');
require('./feature/component-option-spot');
require('./feature/wdh-image-spot');
require('./feature/wdh-rich-text');
require('./feature/wdh-remotecare-admin-panel');

// PROJECT
require('./project/header');
require('./project/footer');
require('@demant/wdh-gdpr/lib/default.implementation.js');