var accountverificationComponent = document.querySelector('.component.remotecare-confirmation');
if (accountverificationComponent) {
  var successbox = accountverificationComponent.querySelector('div.remotecare-confirmation-wrapper.success');
  var errorbox = accountverificationComponent.querySelector('div.remotecare-confirmation-wrapper.error');
  if (location.hash.indexOf("error") > -1 || location.search.indexOf("error") > -1) {
    successbox.style.display = 'none';
    errorbox.style.display = 'block';
  } else {
    successbox.style.display = 'block';
    errorbox.style.display = 'none';
  }
}