/* eslint-disable func-names */
// IMAGE SPOT
import { WDHImageSpot, WDHImageSpotAccordion } from '../../../../wdh-feature/wdh-image-spot';
var imageSpot = new WDHImageSpot();
imageSpot.useEqualHeaderHeight = true;
imageSpot.useEqualBodyHeight = true;
imageSpot.registerVariation(new WDHImageSpotAccordion());
imageSpot.run();
(function ($) {
  window.DGS.OnLoad.getInstance().register(function () {
    window.DGS.Resizer.getInstance().register(function () {
      $('.image-spot .image-spot-element').each(function () {
        var $this = $(this);
        var $link = $('.image-spot-element-link', $this);
        var elemWidth = $link.outerWidth();
        var $a = $('a', $link);
        var linkWidth = $a.length ? $a.outerWidth() : 0;
        $link.css('padding-left', "".concat(elemWidth / 2 - linkWidth / 2, "px"));
      });

      // Show proper image-spot context on resize
      $('.image-spot').each(function () {
        var $this = $(this);
        var $activeLi = $('li.active', $this);
        var $prevLi;
        var isStacked = false;
        var $content = $('.image-spot-element-context', $activeLi);
        var $componentContent = $activeLi.closest('.component-content');
        var $container = $('.context-container', $componentContent);

        // check if elements are stacked.  Then based on that we will hide/show the proper context container
        $activeLi.siblings('.image-spot-element').each(function () {
          var $elem = $(this);
          if ($prevLi != null) {
            if ($elem.offset().top > $prevLi.offset().top) {
              isStacked = true;
            }
          }
          $prevLi = $elem;
        });
        if ($content.length && isStacked) {
          $content.css('display', 'block');
          $container.css('display', 'none');
        } else {
          $content.css('display', 'none');
          $container.css('display', 'block');
        }
      });
    });
    function onAddContentClick(event, data) {
      var $this = $(this);
      var $ul = $this.closest('ul');
      var $lis = $('.image-spot-element', $ul);
      var $li = $this.closest('.image-spot-element');
      var liIndex = $li.index();
      var index = $li.data('context-content-index');
      var division = $li.data('context-content-division');
      var $contentlist = $('.image-spot-element-context', $ul);
      var $content = $('.image-spot-element-context', $li);
      var divisionWidth = 100 / division;
      var selectorleft = divisionWidth * liIndex + divisionWidth / 2;
      var $componentContent = $this.closest('.component-content');
      var $container = $('.context-container', $componentContent);
      var isStacked = $lis.length === 1;
      var $prevLi;
      event.preventDefault();
      if ($content) {
        $lis.each(function () {
          var $curLi = $(this);
          if ($prevLi != null) {
            if ($curLi.offset().top > $prevLi.offset().top) {
              isStacked = true;
            }
          }
          $prevLi = $curLi;
        });
        if (!$container.length) {
          $container = $('<div class="context-container"></div>');
          $componentContent.append($container);
        }
        $lis.removeClass('active');
        if (!isStacked) {
          if ($container.data('context-content-index') !== index) {
            $container.slideUp(0, function () {
              $li.addClass('active');
              $container.html($content.children().clone(true));
              // get selectors border width
              var sbw = parseFloat($('.image-spot-element-context-selector', $container).css('border-left-width'));
              // get active li's padding-left and padding-right
              var apl = parseFloat($li.css('padding-left')) / 2;
              var apr = parseFloat($li.css('padding-right')) / 2;
              // get context-container width
              var ccw = $container.outerWidth();
              // calculate percent
              var percent = (selectorleft / 100 * ccw - sbw + apl - apr) / ccw; // eslint-disable-line no-mixed-operators
              $('.image-spot-element-context-selector', $container).css('left', "".concat(percent * 100, "%"));
              if (data != null && !data.animate) {
                $container.show();
              } else {
                $container.slideDown(0);
              }
            });
            $container.data('context-content-index', index);
          } else {
            $container.slideUp(0);
            $container.data('context-content-index', '-1');
          }
        } else {
          if ($container.data('context-content-index') !== index) {
            $container.data('context-content-index', index);
            $li.addClass('active');
          } else {
            $container.data('context-content-index', '-1');
          }
          // copy to other content container in case of browser resize
          $container.html($content.children().clone(true));
          $container.css('display', 'none');
          $contentlist.each(function () {
            if (this !== $content[0]) {
              $(this).hide('fast');
            } else {
              $content.slideToggle('slow');
            }
          });
          $('.image-spot-element-context-selector', $container).css('left', "".concat(selectorleft, "%"));
        }
      }
    }
    $('.image-spot:not(.accordion)').each(function () {
      var $imageSpot = $(this);
      var index = 0;
      var numLi = $('.image-spot-element', $imageSpot).length;
      $('.image-spot-element', $imageSpot).each(function () {
        var $elem = $(this);
        var $link = $('.image-spot-element-link a', $elem);
        var href = $link.attr('href');
        var $context = $('.image-spot-element-context', $elem);
        var $image = $('.image-spot-element-image', $(this));
        if ($context.length) {
          href = '#';
          $link.attr('href', href);
          $elem.data('context-content-index', index);
          $elem.data('context-content-division', numLi);
          $image.wrap("<a href=\"".concat(href, "\"></a>"));
          $image.parent().on('click', onAddContentClick);
          $link.on('click', onAddContentClick);
        } else if (href !== undefined && href !== '') {
          var $a = $link.clone(true, true);
          $a.html(''); // empty the cloned links text
          $a.attr('class', ''); // empty the cloned links class list
          $image.wrap($a);
        }
        index += 1;
      });
    });
  });
})(jQuery); // eslint-disable-line no-undef